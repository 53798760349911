﻿/*
 *  Document   : _buttons.scss
 *  Author     : RedStar Template
 *  Description: This scss file for button style classes
 */
.example-button-row button,
.example-button-row a {
  margin-right: 8px;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.btn-space {
  margin-right: 10px !important;
}


.mat-mdc-mini-fab {
  // background-color: #fff !important;
  // border: 1px solid #cecece !important;
  // border-radius: 4px !important;
  // box-shadow: none !important;
  height: 32px !important;
  width: 32px !important;

  .mat-icon {
    // color: #0000008A !important;
    font-size: 20px !important;
    line-height: 24px !important;
    width: 30px !important;
  }
}