
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cliniva-primary: mat.define-palette(mat.$indigo-palette);
$cliniva-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cliniva-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cliniva-theme: mat.define-light-theme((
  color: (
    primary: $cliniva-primary,
    accent: $cliniva-accent,
    warn: $cliniva-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cliniva-theme);

/*
 *  Document   : style.css
 *  Author     : RedStar Template
 *  Description: This is a main style scss file for import all scss files.
 *
 *  Structure (with shortcodes):
                 [1. Common ]
                 [2. Fonts ]                   
                 [3. Components ]       
                 [4. Apps]                   
                 [5. Pages ]    
                 [6. Pugins ]        
                 [7. UI ]     
                 [8. Browser ]     

                
/*                 [1. Common ]                   			*/
@import "@angular/material/prebuilt-themes/indigo-pink.css";
/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";
@import "common/_variables";
@import "common/_mixins";
@import "common/_customanimate.scss";
@import "common/_general.scss";
@import "common/_demo.scss";
@import "common/_helpers.scss";
@import "common/_media.scss";
@import "common/_animation.scss";
@import "common/_rtl.scss";

/*                 [2. Fonts]                   				*/
// @import "fonts/_material.scss";
@import "fonts/fontawesome/fontawesome.scss";
@import "fonts/fontawesome/regular.scss";
@import "fonts/fontawesome/solid.scss";
@import "fonts/fontawesome/brands.scss";

/*                 [3. Components ]                   		*/
@import "components/_breadcrumbs.scss";
@import "components/_checkboxradio.scss";
@import "components/_dropdownmenu.scss";
@import "components/_feed.scss";
@import "components/_formcomponents.scss";
@import "components/_infobox.scss";
@import "components/_inputformgroup.scss";
@import "components/_labels.scss";
@import "components/_leftsidebaroverlay.scss";
@import "components/_navbar";
@import "components/_navtabs.scss";
@import "components/_noticeboard.scss";
@import "components/_rightsidebar.scss";
@import "components/_searchbar.scss";
@import "components/_switch.scss";
@import "components/_thumbnails.scss";
@import "components/_todo.scss";
@import "components/_settingSidebar.scss";
@import "components/_scrollbar.scss";

/*                 [4. Apps]                   				*/
@import "apps/_calendar.scss";
@import "apps/_chat.scss";
@import "apps/_contactlist.scss";
@import "apps/_contactgrid.scss";
@import "apps/_dragdrop.scss";
@import "apps/_task.scss";

/*                 [5. Pages ]                   				*/
@import "pages/_dashboard.scss";
@import "pages/_inbox.scss";
@import "pages/_pricing.scss";
@import "pages/_profile.scss";
@import "pages/_timeline.scss";
// @import "pages/_extra_pages.scss";
@import "pages/_auth.scss";

/*                 [6. Pugins ]                   				*/
@import "plugins/_carousel.scss";
@import "plugins/_charts.scss";
@import "plugins/_formwizard.scss";
@import "plugins/_imagegallery.scss";
@import "plugins/_maps.scss";
@import "plugins/_tables.scss";

/*                 [7. UI ]                   */
@import "ui/_alerts.scss";
@import "ui/_badgelistgroupitem.scss";
@import "ui/_buttons.scss";
@import "ui/_card.scss";
@import "ui/_collapse.scss";
@import "ui/_dialogs.scss";
@import "ui/_expansion.scss";
@import "ui/_mediaobject.scss";
@import "ui/_modals.scss";
@import "ui/_pageloader.scss";
@import "ui/_pagination.scss";
@import "ui/_panels.scss";
@import "ui/_preloaders.scss";
@import "ui/_progressbars.scss";
@import "ui/_slider.scss";
@import "ui/_snackbar.scss";
@import "ui/_tabs.scss";
@import "ui/_tooltippopovers.scss";
@import "ui/_ckeditor.scss";

/*                 [8. Browser ]                   			*/
@import "browser/_ie10.scss";
@import "browser/_ie11.scss";
